import * as React from "react";
import { graphql } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";
import wrapWithPostTemplate from "../../src/templates/post";
import Video from "../../src/components/video";

export const frontmatter = {
  title: "Beyond Reach",
  subtitle:
    "Escape the space station. Trouble is: there is no gravity in space...",
  meta: "Godot Engine • Posted January 12, 2020",
  author: "hello@golsteyn.com",
  category: "projects",
  date: "2020-01-12T00:00:00.000Z",
};

const Beyond = () => (
  <>
    <p>
      For the 2020 nwHacks Hackathon, my friends and I decided to design and
      develop a video game using the{" "}
      <a href="https://godotengine.org/">Godot Engine</a>. In 24 hours, we
      wanted to create a game from start to finish, including creating the game
      art ourselves. We ultimately created Beyond Reach, a 2D platformer set in
      a martian space station. You are in danger and need to escape. Trouble is,
      there is no gravity in space and any wrong move could mean you will end up
      floating away forever.
    </p>
    <figure className="full raise">
      <Video
        src="https://player.vimeo.com/video/415990979?autoplay=1"
        image={<StaticImage src="../image/beyond/beyond-vid.png" />}
        ratio={10 / 17}
      />
      <figcaption>
        The player is tasked with jumping from ladder to ladder, whether affixed
        to a wall or crate. In certain levels, you need to use the laser to open
        doors to get access to the next stage. In other levels, you need to jump
        on crates to help you access new areas of the map.
      </figcaption>
    </figure>
    <p>
      Players control their character with the mouse. <kbd>left click</kbd>{" "}
      makes the character float to the target, <kbd>right click</kbd> shoots a
      laser that activates doors and other switches. Once you let go of any
      ladder, you can no longer change your course until you manage to grab
      another surface, whether a crate or a wall. Therefore, you must be certain
      your next jump will bring you where you want to be. Thankfully, you can
      always restart the level by pressing <kbd>R</kbd>.
    </p>
    <figure>
      <StaticImage src="../image/beyond/beyond-player.png" />
      <figcaption>
        The player art was particularly challenging (and fun!) to make.
      </figcaption>
    </figure>
    <figure>
      <audio controls>
        <source src="/beyond.mp3" type="audio/mpeg" />
        <track kind="captions" />
      </audio>
      <figcaption>The main game soundtrack, composed in Garageband</figcaption>
    </figure>
    <p>
      I also worked on the game art and soundtrack, making this project my first
      introduction to pixel art and music composition. I am quite happy with the
      end result!
    </p>
  </>
);

export const query = graphql`
  query($id: String) {
    javascriptFrontmatter(id: { eq: $id }) {
      frontmatter {
        author {
          email
          firstName
          name
        }
        category {
          name
        }
        meta
        subtitle
        title
        date
      }
    }
  }
`;

export default wrapWithPostTemplate(
  Beyond,
  <img src="/image/header/space.svg" alt="" className="hero_image" />
);
