import React from "react";

import "./style.scss";

const PageCounter = () => {
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    const getPagecount = async () => {
      const result = await fetch("/.netlify/functions/getTotalPageview", {
        method: "POST",
        body: JSON.stringify({
          path: window.location.pathname,
        }),
      });

      const { count } = await result.json();

      setCount(count);
    };

    getPagecount();
  }, []);

  return (
    <div className="page-counter">
      <span>{!!count ? String(count).padStart(6, "0") : "------"}</span>
    </div>
  );
};

export default PageCounter;
