import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import classes from "../../utils/classes";

import "./index.scss";

const AboutContainer = ({ className, ...props }) => {
  return (
    <div className={classes("about-container", className)}>
      <div className="portrait">
        <StaticImage
          src="../../../content/image/quentin.jpg"
          alt="Hi! It's me!"
          backgroundColor="rgb(34, 61, 125, 0.2)"
        />
      </div>
      <span className="title">
        Written by{" "}
        <Link className="highlight" to="/about">
          Quentin Golsteyn
        </Link>
        <br />
      </span>
      <span className="subtitle">
        A front-end developer based in Vancouver, Canada.
      </span>
    </div>
  );
};

export default AboutContainer;
