import { motion } from "framer-motion";
import React from "react";
import IframeContainer from "../iframe";

import "./index.scss";

const Video = ({ image, ratio, src }) => {
  const [showVideo, setShowVideo] = React.useState(false);

  return (
    <IframeContainer
      className="player-container"
      ratio={ratio}
      onClick={() => {
        setShowVideo(true);
      }}
    >
      <motion.div
        className="player-overlay"
        animate={showVideo ? "play" : "initial"}
      >
        <motion.div
          variants={{
            initial: { opacity: 1 },
            play: { opacity: 0, transition: { delay: 1 } },
          }}
        >
          {image}
        </motion.div>
        <motion.div
          className="play-button"
          variants={{
            initial: { opacity: 1 },
            play: { opacity: 0, transition: { duration: 0.8 } },
          }}
        />
      </motion.div>
      {showVideo && (
        <motion.iframe
          src={src}
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
        />
      )}
    </IframeContainer>
  );
};

export default Video;
