import React from "react";
import classes from "../../utils/classes";

import "./index.scss";

const IframeContainer = ({ ratio, style, className, ...props }) => {
  return (
    <div
      className={classes("iframe-container", className)}
      style={{ ...style, paddingBottom: `${ratio * 100}%` }}
      {...props}
    />
  );
};

export default IframeContainer;
