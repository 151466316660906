import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ title, description, canonical, datePublished, isPost }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="og:site_name" content="Quentin Golsteyn" />
      <meta name="og:title" content={title} />
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={canonical} />
      {isPost && (
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "headline": ${title},
          "datePublished": ${datePublished},
          "author": {
              "@type": "Person",
              "name": "Quentin Golsteyn",
              "url": "https://golsteyn.com/about"
          }
        }
        `}</script>
      )}
    </Helmet>
  );
};

export default Meta;
