import { motion, useAnimation } from "framer-motion";
import React from "react";

import "./noteWrapper.scss";

export const NoteWrapperContext = React.createContext({
  show: () => {},
  hide: () => {},
});

const NoteWrapper = ({ children }) => {
  const currentElementRef = React.useRef(undefined);
  const scrollListenerRef = React.useRef(undefined);
  const control = useAnimation();

  const hide = (element, quickUpdate = false) => {
    if (quickUpdate) {
      element.id = null;
    } else {
      setTimeout(() => {
        element.id = null;
      }, 400);
    }

    control.start("hide");

    if (scrollListenerRef.current) {
      document.removeEventListener("scroll", scrollListenerRef.current);
    }

    currentElementRef.current = undefined;
  };

  const show = (element) => {
    if (currentElementRef.current) {
      hide(currentElementRef.current, true);
    }

    currentElementRef.current = element;

    element.id = "currentNote";
    control.start("show");

    scrollListenerRef.current = () => {
      const rect = element.getBoundingClientRect();

      if (
        rect.top >
          (window.innerHeight || document.documentElement.clientHeight) ||
        rect.bottom < 0
      ) {
        hide(element);
      }
    };

    document.addEventListener("scroll", scrollListenerRef.current);
  };

  const citeArrayRef = React.useRef([]);

  return (
    <NoteWrapperContext.Provider
      value={{ show, hide, citeArray: citeArrayRef.current }}
    >
      <motion.div
        className="note-wrapper"
        variants={{
          show: {
            translateX: "-60%",
          },
          hide: {
            translateX: "0%",
          },
        }}
        transition={{
          duration: 0.4,
          ease: "easeInOut",
        }}
        animate={control}
      >
        {children}
      </motion.div>
    </NoteWrapperContext.Provider>
  );
};

export default NoteWrapper;
